<template>
  

    <div :class="['w-full h-full overflow-hidden rounded-md duration-200 flex flex-col justify-start items-start relative']">

   
      
      <ClientOnly>
        <simplebar class="w-full h-full overflow-y-auto ">
          <LazyHomeCalendar ></LazyHomeCalendar>
        </simplebar>
        <LazyWatermark class="z-10 pointer-events-none top-0 left-0" v-if="$address" :watertext="$address"></LazyWatermark>
      </ClientOnly>

    </div>


</template>

<script setup>

const showCalendarPane = ref(true);

import simplebar from "simplebar-vue";
import "simplebar-vue/dist/simplebar.min.css";

const { $address } = useNuxtApp()


</script>

<style>

</style>